/*---------- Google Fonts ----------*/

body {
  font-family: "DMSansMedium";
  background-color: #0a1010;
  color: #fff;
}

figure {
  margin: 0;
}

a,
input,
button,
textarea {
  outline: none !important;
}

a {
  text-decoration: none;
  transition: all ease-in-out 300ms;
  -webkit-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
}

.container-fluid,
.container {
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row > * {
  padding-left: 15px;
  padding-right: 15px;
}

p {
  font-family: inherit;
  font-size: 18px;
  line-height: 30px;
  color: inherit;
}
p a {
  text-decoration: none;
  color: inherit;
}

p a:hover {
  text-decoration: none;
  color: #bbeadd;
}

ul li,
ol li {
  font-size: 24px;
  line-height: 36px;
  color: #3a3a3a;
}

ul li a,
ol li a {
  text-decoration: none;
  color: inherit;
}

ul li a:hover,
ol li a:hover {
  text-decoration: none;
  color: #bbeadd;
}

ul,
ul li,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a {
  padding: 0;
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-control:focus {
  box-shadow: none;
  border-color: transparent;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
}

/* colors */

.lightGreen {
  color: #86ed82 !important;
}

.intenseWhite {
  color: #fefefe !important;
}

.smokyBlack {
  color: #0a1010 !important;
}

.jungleGreen {
  color: #103031 !important;
}

.aquamarine {
  color: #57e6aa !important;
}

/* colors */

/* buttons */

.main-title {
  color: #fff;
  font-family: "DMSansMedium";
  font-size: 90px;
  line-height: 100.8%;
  letter-spacing: -2.7px;
}

.main-title * {
  letter-spacing: inherit;
  font-size: inherit;
  line-height: inherit;
}

.block-title {
  color: #fff;
  font-family: "DMSansMedium";
  font-size: 60px;
  line-height: 100%;
  letter-spacing: -3px;
}

.block-title * {
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

.regular-title {
  color: #fff;
  font-family: "DMSansMedium";
  font-size: 40px;
  line-height: 100%;
  letter-spacing: -1.2px;
}

.regular-title * {
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

/* font-family: "DMSansMedium"; */
.regular-text {
  font-family: "DMSansRegular";
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -1.2px;
}

.regular-text * {
  font-size: inherit;
  letter-spacing: inherit;
  line-height: inherit;
}

.team-title {
  color: #fefefe;
  text-align: center;
  font-family: "DMSansRegular";
  font-size: 20px;
  line-height: normal;
}

.team-title * {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* ---- buttons ---- */
.btn {
  text-align: center;
  padding: 16px 32px;
  border-radius: 12px;
  background: linear-gradient(
      103deg,
      #4ee1f9 -37.12%,
      #86ed82 28.81%,
      #bbf910 90.91%
    ),
    #fefefe;
  color: #0d1014;
  font-family: "DMSansMedium";
  font-size: 20px;
  line-height: normal;
  transition: all ease-in-out 400ms;
  -webkit-transition: all ease-in-out 400ms;
  -ms-transition: all ease-in-out 400ms;
  -o-transition: all ease-in-out 400ms;
  -moz-transition: all ease-in-out 400ms;
}

.btn:hover {
  color: #0d1014;
  border-radius: 12px;
  background: linear-gradient(
      0deg,
      rgba(254, 254, 254, 0.4) 0%,
      rgba(254, 254, 254, 0.4) 100%
    ),
    linear-gradient(103deg, #4ee1f9 -37.12%, #86ed82 28.81%, #bbf910 90.91%),
    #fefefe;
  transition: all ease-in-out 400ms;
  -webkit-transition: all ease-in-out 400ms;
  -ms-transition: all ease-in-out 400ms;
  -o-transition: all ease-in-out 400ms;
  -moz-transition: all ease-in-out 400ms;
}

.btn:focus,
.btn:visited {
  color: #0d1014 !important;
  border-radius: 12px;
  background: linear-gradient(
      0deg,
      rgba(254, 254, 254, 0.7) 0%,
      rgba(254, 254, 254, 0.7) 100%
    ),
    linear-gradient(103deg, #4ee1f9 -37.12%, #86ed82 28.81%, #bbf910 90.91%),
    #fefefe;
  transition: all ease-in-out 400ms;
  -webkit-transition: all ease-in-out 400ms;
  -ms-transition: all ease-in-out 400ms;
  -o-transition: all ease-in-out 400ms;
  -moz-transition: all ease-in-out 400ms;
}

/* ----------- common used fonts --------------- */

@media (min-width: 1400px) {
  .container {
    max-width: 1310px;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .main-title {
    font-size: 76px;
  }
  .block-title {
    font-size: 54px;
  }
  .regular-text {
    font-size: 22px;
  }
  .regular-title {
    font-size: 34px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-title {
    font-size: 64px;
  }
  .regular-text {
    font-size: 20px;
  }
  .block-title {
    font-size: 50px;
  }
  .regular-title {
    font-size: 32px;
  }
  .btn {
    padding: 14px 32px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-title {
    font-size: 58px;
  }
  .regular-text {
    font-size: 18px;
  }
  .block-title {
    font-size: 48px;
  }
  .regular-title {
    font-size: 28px;
  }
  .btn {
    padding: 14px 32px;
  }
}

@media (max-width: 767px) {
  .main-title {
    font-size: 60px;
  }
  .block-title {
    font-size: 40px;
    letter-spacing: -2px;
  }
  .regular-text {
    font-size: 18px;
  }
  .regular-title {
    font-size: 24px;
  }
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .team-title {
    font-size: 14px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
}

@media (min-width: 240px) and (max-width: 575px) {
}

@media (max-width: 374px) {
  .main-title {
    font-size: 42px;
  }
  .block-title {
    font-size: 30px;
    letter-spacing: -2px;
  }
  .regular-text {
    font-size: 16px;
  }
}

@media (min-width: 1400px) {
}

@media (min-width: 1300px) and (max-width: 1399px) {
}
@media (min-width: 1200px) and (max-width: 1399px) {
}
@media (min-width: 992px) and (max-width: 1199px) {
}
@media (max-width: 991px) {
}
@media (min-width: 768px) and (max-width: 991px) {
}
@media (min-width: 768px) {
}
@media (max-width: 767px) {
}
@media (min-width: 576px) and (max-width: 767px) {
}
@media (min-width: 240px) and (max-width: 575px) {
}
@media (max-width: 374px) {
}
